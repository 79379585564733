import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const CareerForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    position: '',
    coverLetter: '',
    linkedIn: '',
    portfolio: '',
    resume: null, // Add resume to the state
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleFileChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     resume: e.target.files[0], // Handle file input
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }

    try {
      const response = await fetch('https://applobackend.skahmerali.com/api/career', {
        method: 'POST',
        body: form,
      });

      if (response.ok) {
        toast.success('Application submitted successfully');
        // Reset form
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          position: '',
          coverLetter: '',
          linkedIn: '',
          portfolio: '',
          resume: ""||null,
        });
      } else {
        toast.error('Error submitting application, try again');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting application, try again');
    }
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
      <ToastContainer />
      <div className="max-w-7xl w-full space-y-8">
        <div className="bg-gray-200 shadow-lg rounded-lg p-8">
          <h2 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4 text-center">
            <span className='text-orange-500'>Apply</span> Now<span className='text-orange-500'> !</span>
          </h2>
          <p className="text-sm text-gray-600 mb-6 text-center">
            Join our team and make a difference!
          </p>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  required
                  value={formData.fullName}
                  onChange={handleChange}
                  className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                  placeholder="Full name"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                  placeholder="Phone number"
                />
              </div>
              <div className="relative">
                <label htmlFor="position" className="block text-sm font-medium text-gray-700">Position Interested In</label>
                <select
                  id="position"
                  name="position"
                  required
                  value={formData.position}
                  onChange={handleChange}
                  onClick={() => setIsOpen(!isOpen)}
                  className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm pl-3 pr-10"
                >
                  <option value="" disabled>Select a position</option>
                  <option value="Frontend Developer">Frontend Developer</option>
                  <option value="Backend Developer">Backend Developer</option>
                  <option value="Full Stack Developer">Full Stack Developer</option>
                  <option value="DevOps Engineer">DevOps Engineer</option>
                  <option value="UI/UX Designer">UI/UX Designer</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="Sales Executive">Sales Executive</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-5 pt-5 pointer-events-none">
                  {isOpen ? <FaChevronUp className="text-gray-500" /> : <FaChevronDown className="text-gray-500" />}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="coverLetter" className="block text-sm font-medium text-gray-700">Cover Letter</label>
              <textarea
                id="coverLetter"
                name="coverLetter"
                required
                value={formData.coverLetter}
                onChange={handleChange}
                className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                placeholder="Your cover letter"
              ></textarea>
            </div>
            <div>
              <label htmlFor="linkedIn" className="block text-sm font-medium text-gray-700">LinkedIn Profile Link</label>
              <input
                id="linkedIn"
                name="linkedIn"
                
                pattern="https?://.+|www\..+"
                value={formData.linkedIn}
                onChange={handleChange}
                className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                placeholder="LinkedIn profile Link"
              />
            </div>
            <div>
              <label htmlFor="portfolio" className="block text-sm font-medium text-gray-700">Portfolio/Website Link</label>
              <input
                id="portfolio"
                name="portfolio"
                pattern="https?://.+|www\..+"
              
                value={formData.portfolio}
                onChange={handleChange}
                className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                placeholder="Portfolio or website Link"
              />
            </div>
            {/* Resume Upload Field */}
            {/* <div>
              <label htmlFor="resume" className="block text-sm font-medium text-gray-700">
                Upload Resume
              </label>
              <input
                id="resume"
                name="resume"
                type="file"
                required
                onChange={handleFileChange}
                className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              />
            </div> */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="relative text-white px-8 py-3 rounded-3xl text-xl font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300"
              >
                Apply Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
