import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaMapMarkerAlt } from 'react-icons/fa';
import { FaPhone, FaEnvelope, FaBuilding } from "react-icons/fa";
import Aboutbg from '../assets/aboutbg.jfif'
import { AiFillFacebook, AiFillTwitterCircle, AiFillLinkedin, AiFillInstagram } from "react-icons/ai";
import { BsFlag } from "react-icons/bs";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
const ContactUs = () => {


  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    interested: '',
    message: ''
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form submitted:', formData);
  //   setFormData({
  //     fullName: '',
  //     email: '',
  //     phone: '',
  //     interestedIn: '',
  //     message: ''
  //   });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://applobackend.skahmerali.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
       
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID, 
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
          formData, 
          process.env.REACT_APP_EMAILJS_USER_ID
        );
        // console.log(formData);
        toast.success('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          interested: '',
          message: ''
        });
      } else {
        toast.error('Failed to send the message. Please try again.');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-800 py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
    {/* Animated Background */}
    <div className="absolute inset-0 z-0">
      <div className="rotating-bg w-full h-full absolute">
        <div className="rotating-circle absolute bg-orange-500 w-72 h-72 rounded-full animate-spin-slow opacity-20"></div>
        <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
        <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '20%', right: '15%' }} />
     
      </div>
    </div>
    
    <ToastContainer/>
      <div className="max-w-7xl w-full space-y-8 pt-9 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="mt-6 text-left text-4xl md:text-6xl font-extrabold text-gray-100">
            <span className='text-orange-500'>Get </span>Started<span className='text-orange-500'> !</span>
          </h2>
          <p className="mt-2 text-left text-sm text-gray-200">
            We'd love to hear from you!
          </p>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="fullName" className="block text-sm font-medium text-gray-200">Full Name</label>
                  <input
                    id="fullName"
                    name="name"
                    type="text"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                    placeholder="Full name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-200">Email Address</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-200">Phone Number</label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                    placeholder="Phone number"
                  />
                </div>
                <div className="relative">
                  <label htmlFor="interestedIn" className="block text-sm font-medium text-gray-200">Interested In</label>
                  <select
                    id="interestedIn"
                    name="interested"
                    required
                    value={formData.interested}
                    onChange={handleChange}
                    onClick={() => setIsOpen(!isOpen)}
                    className="appearance-none rounded-xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm pl-3 pr-10"
                  >
                    <option value="" disabled>Select an option</option>
                    <option value="Mobile app development">Mobile app development</option>
                    <option value="Web development">Web development</option>
                    <option value="Web designing">Web designing</option>
                    <option value="Graphic designing">Graphic designing</option>
                    <option value="UI/UX designing">UI/UX designing</option>
                    <option value="Software Development">Software Development</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Professional Photography">Professional Photography</option>
                    <option value="Others">Others</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-5 pt-5 pointer-events-none">
                    {isOpen ? <FaChevronUp className="text-gray-500" /> : <FaChevronDown className="text-gray-500" />}
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-200">Message</label>
                <textarea
                  id="message"
                  name="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                  placeholder="Your message"
                ></textarea>
              </div>
              <div className="flex justify-start">
                <button
                  type="submit"
                  className="relative text-white px-8 py-3 rounded-3xl text-xl font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="bg-gray-900 text-white p-8 rounded-3xl flex flex-col space-y-8 md:space-y-16">
          <div className="flex flex-col space-y-4">
            <h3 className="text-3xl md:text-4xl font-bold text-center mb-4">Contact Information</h3>

            <div className="flex flex-col md:flex-row md:justify-between items-start space-y-8 md:space-y-0">
              <div className="flex flex-col space-y-6 w-full md:w-1/2">
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-orange-500 text-xl" />
                  <p className="font-bold text-xl md:text-2xl">Call Us</p>
                </div>
                <p className="flex items-center space-x-2">
                  <BsFlag className="text-xl" />
                  <span>+92 346 2858293</span>
                </p>
                {/* <p className="flex items-center space-x-2 mt-1">
                  <BsFlag className="text-xl" />
                  <span>+1 123 456 7890</span>
                </p> */}
                <p className="flex items-center space-x-2 mt-1">
                  <FaEnvelope className="text-orange-500 text-xl" />
                  <span className='break-all'>Info@applotech.com
                  </span>
                </p>
                <p className="flex items-center space-x-2 mt-1">
                  <FaEnvelope className="text-orange-500 text-xl" />
                  <span className='break-all'>Contact@applotech.com
                  </span>
                </p>
              
              </div>

              <div className="flex flex-col space-y-4 w-full md:w-1/2">
                <div className="flex items-center space-x-2">
                  <FaBuilding className="text-orange-500 text-xl" />
                  <h4 className="text-xl md:text-2xl font-bold">Office</h4>
                </div>
                {/* <div className="flex items-center space-x-2">
                  <BsFlag className="text-xl" />
                  <div>
                    <p className="font-bold">USA Office</p>
                    <p>123 Main St, Anytown, USA</p>
                  </div>
                </div> */}
                <div className="flex items-center space-x-2">
                  <FaMapMarkerAlt className="text-xl" />
                  <div>
                    {/* <p className="font-bold">Office</p> */}
                    <p>Karachi, Pakistan</p>
                  </div>
                </div>
                <p className="flex items-center space-x-2 mt-1">
                  <FaEnvelope className="text-orange-500 text-xl" />
                  <span className='break-all'>Career@applotech.com 
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Social Media */}
          <div className=" p-4 rounded-lg">
            <h4 className="text-2xl md:text-3xl font-bold mb-6 text-center text-white">Follow Us</h4>
            <div className="flex justify-center space-x-0 lg:space-x-0 ">
           <Link to="https://www.facebook.com/applotech" target='_blank'>  <AiFillFacebook className="text-orange-500 text-5xl md:text-6xl hover:text-white transition duration-300 bg-black rounded-full p-2" /></Link>
              <AiFillTwitterCircle className="text-orange-500 text-5xl md:text-6xl hover:text-white transition duration-300 bg-black rounded-full p-2" />
              <Link to="https://www.linkedin.com/company/applotech/" target='_blank'>   <AiFillLinkedin className="text-orange-500 text-5xl md:text-6xl hover:text-white transition duration-300 bg-black rounded-full p-2" /></Link>
              <AiFillInstagram className="text-orange-500 text-5xl md:text-6xl hover:text-white transition duration-300 bg-black rounded-full p-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
